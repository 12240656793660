import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { myClients } from '../../constants/clients'; // Assurez-vous que le chemin d'accès est correct

export function MyClients() {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);

	return (
		<section>
			<div className="custom-container">
				<div className="services-content content-width">
					<div className="clients-logos">
						<h4 className="scroll-animation" data-aos="fade-up">
							Nous avons collaboré ensemble
						</h4>
						<div className="row align-items-center">
							{myClients.map((client, index) => (
								<div
									key={index}
									className={`col-md-3 scroll-animation`}
									data-aos={`fade-${
										['right', 'up', 'down', 'left'][index % 4]
									}`}
								>
									<a href={client.url} target="_blank">
										{' '}
										<img
											src={client.src}
											alt={client.alt}
											width={80}
											height={80}
											style={{
												objectFit: 'contain',
											}}
										/>
									</a>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
