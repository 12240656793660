export const heroData = {
	intro: 'Bienvenue',
	title: 'Je suis Lorcann Développeur Web3',
	description:
		"Avec plus de 5 ans d'expérience dans le domaine de la programmation, je suis un passionné de blockchain et de technologie de manière générale. J'ai collaboré avec diverses startups et agences, développant des solutions créatives et innovantes. J'ai également eu l'opportunité de former une centaine d'étudiants/professionnels dans plusieurs d'écoles, transmettant ma passion contagieuse pour la technologie. Depuis 2017, je suis impliqué dans l'écosystème blockchain car je crois en la décentralisation et en la liberté qu'elle apporte.",
	schoolsCount: '10+',
	schoolsText: 'Écoles intervenues',
	experienceYears: '5+',
	experienceText: "Années d'expériences",
};
