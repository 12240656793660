import React, { useRef, useEffect, useState } from 'react';
import OwlCaraousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { testimonialsData } from '../../constants/testimonial';

export function Testimonials() {
	const carouselRef = useRef(null);
	const [count, setCount] = useState(1);

	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);

	const handlePrevClick = () => {
		console.log(carouselRef.current);
		carouselRef.current.prev();
		// if(count < 1) setCount(count - 1);
	};

	const handleNextClick = () => {
		console.log(carouselRef.current);
		carouselRef.current.next();
		// if(count < testimonialsData.length) setCount(count + 1);
	};

	return (
		<section
			className='testimonial-area page-section scroll-to-page'
			id='testimonial'>
			<div className='custom-container'>
				<div className='testimonial-content content-width'>
					<div className='section-header'>
						<h4 className='subtitle scroll-animation' data-aos='fade-up'>
							<i className='lar la-comment'></i> Avis
						</h4>
						<h1 className='scroll-animation' data-aos='fade-up'>
							Ce qu'ils <span>disent</span> sur moi
						</h1>
					</div>
					<div
						className='testimonial-slider-wrap scroll-animation'
						data-aos='fade-up'>
						<OwlCaraousel
							className='owl-carousel testimonial-slider owl-theme'
							smartSpeed='450'
							items='1'
							dots={false}
							ref={carouselRef}>
							{testimonialsData.map((testimonial, index) => (
								<div className='testimonial-item' key={index}>
									<div className='testimonial-item-inner'>
										<div className='author d-flex align-items-center'>
											{/* Image et autres informations de l'auteur ici */}
											<div className='right'>
												<h3>{testimonial.name}</h3>
												<p className='designation'>{testimonial.role}</p>
											</div>
										</div>
										<p>“{testimonial.comment}”</p>
										{/* Lien vers le projet ou le profil de l'auteur, si nécessaire */}
									</div>
								</div>
							))}
						</OwlCaraousel>
						<div className='testimonial-footer-nav'>
							<div className='testimonial-nav d-flex align-items-center'>
								<button className='prev' onClick={handlePrevClick}>
									<i className='las la-angle-left'></i>
								</button>
								{/* <div id="testimonial-slide-count"><span className="left">{count}</span> / {testimonialsData.length}</div> */}
								<button className='next' onClick={handleNextClick}>
									<i className='las la-angle-right'></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
