import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { heroData } from '../../constants/presentation';

export function Hero() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section className="hero-section page-section scroll-to-page" id="home">
            <div className="custom-container">
                <div className="hero-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-home"></i> {heroData.intro}
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>{heroData.title}</h1>
                    </div>
                    <p className="scroll-animation" data-aos='fade-up'>{heroData.description}</p>
                    <div className="facts d-flex">
                        <div className="fact-item scroll-animation" data-aos='fade-right'>
                            <h1>{heroData.schoolsCount}</h1>
                            <p>{heroData.schoolsText}</p>
                        </div>
                        <div className="fact-item scroll-animation" data-aos='fade-left'>
                            <h1>{heroData.experienceYears}</h1>
                            <p>{heroData.experienceText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
