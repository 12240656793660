import React, { useState } from 'react';
import { menuLinks, socialLinks } from '../../constants/menu';

export function RightMenu() {
    const [isActive, setIsActive] = useState(false);

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    return (
        <div>
            <span className="icon-menu" onClick={toggleMenu}>
                <span className="bar"></span>
                <span className="bar"></span>
            </span>
            <div className={`responsive-sidebar-menu ${isActive ? 'active' : ''}`}>
                <div className="overlay" onClick={toggleMenu}></div>
                <div className="sidebar-menu-inner">
                    <div className="menu-wrap">
                        <p>Menu</p>
                        <ul className="menu scroll-nav-responsive d-flex">
                            {menuLinks.map(link => (
                                <li key={link.name}>
                                    <a className="scroll-to" href={link.href} onClick={toggleMenu}>
                                        <i className={link.icon}></i> <span>{link.name}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="sidebar-social">
                        <p>Social</p>
                        <ul className="social-links d-flex align-items-center">
                            {socialLinks.map(link => (
                                <li key={link.name}>
                                    <a href={link.href}><i className={link.icon}></i></a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
