export const menuLinks = [
	{ name: 'Bienvenue', icon: 'las la-home', href: '#home' },
	{ name: 'Services', icon: 'las la-stream', href: '#services' },
	// { name: 'Parcours', icon: 'las la-briefcase', href: '#resume' },
	{ name: 'Compétences', icon: 'las la-shapes', href: '#skills' },
	// { name: 'Portfolios', icon: 'las la-grip-vertical', href: '#portfolio' },
	{ name: 'Avis', icon: 'lar la-comment', href: '#testimonial' },
	// { name: "Contact", icon: "las la-envelope", href: "#contact" },
];

export const socialLinks = [
	{
		name: 'Linkedin',
		icon: 'lab la-linkedin',
		href: 'https://www.linkedin.com/lorcannrauzduel',
	},
	{
		name: 'Instagram',
		icon: 'lab la-instagram',
		href: 'https://instagram.com/lorcann.pro',
	},
	{
		name: 'Github',
		icon: 'lab la-github',
		href: 'https://github.com/lorcannrauzduel',
	},
];
