import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { pricingPackages } from '../../constants/pricing';

export function Pricing() {
	useEffect(() => {
		AOS.init({ duration: 1000 });
	}, []);

	return (
		<section className='pricing-area page-section scroll-to-page' id='pricing'>
			<div className='custom-container'>
				<div className='pricing-content content-width'>
					{/* En-tête de la section */}
					<div className='section-header'>
						<h4 className='subtitle scroll-animation' data-aos='fade-up'>
							<i className='las la-dollar-sign'></i> Tarification
						</h4>
						<h1 className='scroll-animation' data-aos='fade-up'>
							Mes <span>Tarifs</span>
						</h1>
					</div>
					<div className='pricing-table-items'>
						<div className='row'>
							{pricingPackages.map((pack, index) => (
								<div
									key={index}
									className='col-md-6 scroll-animation'
									data-aos='fade-up'>
									<div className='pricing-table'>
										<div className='pricing-table-header'>
											<div className='top d-flex justify-content-between align-items-start'>
												<h4>{pack.name}</h4>
												<p className='text-right'>{pack.description}</p>
											</div>
											<h1>{pack.price}</h1>
										</div>
										<ul className='feature-lists'>
											{pack.features.map((feature, featureIndex) => (
												<li key={featureIndex}>{feature}</li>
											))}
										</ul>
										<a
											href={pack.buttonLink}
											className='theme-btn'
											target='_blank'
											rel='noreferrer'>
											{pack.buttonText}
										</a>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
