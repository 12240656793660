import React from 'react';
import { menuLinks } from '../../constants/menu'; // Assurez-vous que le chemin d'accès est correct

export function Scrollnav() {
    return (
        <ul className="menu scroll-nav d-flex">
            {menuLinks.map(link => (
                <li key={link.name}>
                    <a className="scroll-to" href={link.href}>
                        <span>{link.name}</span> <i className={link.icon}></i>
                    </a>
                </li>
            ))}
        </ul>
    );
}
