import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { skillsData } from '../../constants/skills';

export function Skills() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section className="skills-area page-section scroll-to-page" id="skills">
            <div className="custom-container">
                <div className="skills-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-shapes"></i> Mes compétences
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Mes <span>Points forts</span></h1>
                    </div>

                    <div className="row skills text-center">
                        {skillsData.map((skill, index) => (
                            <div className="col-md-3 scroll-animation" data-aos='fade-up' key={index}>
                                <div className="skill">
                                    <div className="skill-inner">
                                        <img src={skill.imgSrc} alt={skill.name} width={100} height={100}/>
                                    </div>
                                    <p className="name">{skill.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>    
                </div>
            </div>
        </section>
    );
}
