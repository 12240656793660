import React from 'react';
import { profileData } from '../../constants/profile'; // Assurez-vous que le chemin d'accès est correct

export function LeftMenu() {
	return (
		<div className='left-sidebar'>
			<div className='sidebar-header d-flex align-items-center justify-content-between'>
				<h2>
					<strong>Lorcann ®</strong>
				</h2>
				<span className='designation'>{profileData.designation}</span>
			</div>
			<img className='me' src={profileData.profileImage} alt='Me' />
			<h2 className='email'>{profileData.email}</h2>
			<h2 className='address'>{profileData.address}</h2>
			<p className='copyright'>{profileData.copyright}</p>
			<ul className='social-profile d-flex align-items-center flex-wrap justify-content-center'>
				<li>
					<a href={profileData.socialLinks.linkedin}>
						<i className='lab la-linkedin' target='_blank'></i>
					</a>
				</li>
				<li>
					<a href={profileData.socialLinks.github}>
						<i className='lab la-github' target='_blank'></i>
					</a>
				</li>
				<li>
					<a href={profileData.socialLinks.instagram}>
						<i className='lab la-instagram' target='_blank'></i>
					</a>
				</li>
			</ul>
			<a
				href='https://api.whatsapp.com/send/?phone=0698670746&text&type=phone_number&app_absent=0'
				className='theme-btn'
				target='_blank'
				rel='noreferrer'>
				<i className='las la-envelope'></i> Contacte moi
			</a>
		</div>
	);
}
