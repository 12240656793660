export const testimonialsData = [
	{
		name: 'Pauline Gigon',
		role: 'Data Manager chez Sirdata',
		comment:
			'Merci encore pour ce cours de Blockchain Lorcann ! Pour ma part, je le recommande, il saura vous faire aimer la cryptomonnaie (ce qui n’est pas toujours évident 😅).',
	},
	{
		name: 'Sitraka Rakotovao',
		role: 'Étudiant à Epitech Digital',
		comment:
			'Sujet intéressant sur tout ce qui est crypto, blockchain, NFT et Web3 en général, prof sympa, toujours prêt à répondre à nos questions sur ces sujets-là, je le recommande vivement!',
	},
	{
		name: 'Khaled Belarbi',
		role: 'Consultant IT chez Exens Ingénierie',
		comment:
			"Lorcann is an amazing teacher with good experience in Dapp & Smart contract development. It's been a pleasure working with him, definitely recommend him.",
	},
	{
		name: 'Thibault Dulon',
		role: 'Lead Developer & Architect chez Mangopay',
		comment:
			'Lorcann was really supportive and helpful during my formation. He always pointed me out some useful documentation or resources to help me understand core concepts of web3 and its technologies. He also spent time to give me great hints on my projects or my exercises and always tried to explain everything. Definitely a great trainer.',
	},
	{
		name: 'Florian Mulumba',
		role: 'Consultant Media chez Labelium',
		comment:
			"Merci à toi Lorcann pour toutes les connaissances que tu nous as apporté sur le sujet de la Blockchain et de nous avoir démontré que créer sa propre crypto ce n'était pas si compliqué que ça ! Ton approche pédagogique et ton expérience font de toi un Formateur Blockchain idéal pour les écoles qui souhaitent aborder le sujet.👍",
	},
	{
		name: 'Thibaut Lefebvre',
		role: 'CTO My Lovely Planet',
		comment:
			'Lorcann est un excellent formateur, très pédagogue et patient ! Je recommande fortement !',
	},
	{
		name: 'Mathis Cordero',
		role: 'Freelance en Acquisition digitale',
		comment:
			'Lorcann a réalisé une masterclass sur la thématique blockchain lors de mon M2 chez Digital Campus, il a été capable de vulgariser et d’expliquer les bases simplement pour attirer de nouvelles personnes à s’intéresser à ces technologies. Merci pour cette demi-journée très instructive !',
	},
    {
        name: "Alexis Veyres",
        role: "Assistant Manager",
        comment: "Lorcann a été un intervenant pour une initiation et découverte du codage à l'ISG. Une semaine enrichissante, à la découverte d'un univers que je ne connaissais pas et qui, grâce à Lorcann, m'a intéressé. Il a très bien su m'intéresser et m'expliquer, un domaine qui ne m'attirait pas du tout avant. Il a été un bon professeur. Je l'en remercie."
    },
	{
		name: 'Ruth YAPOGA',
		role: 'Chargée de marketing digital chez ICPF',
		comment:
			"Une pédagogie efficace, une approche du sujet de la blockchain pertinente et des prises de position constructives qui élèvent l'échange. Merci pour cette initiation complète et intéressante sur le sujet de la blockchain !",
	},
	{
		name: 'Yoan Osseni',
		role: 'Consultant Blockchain',
		comment:
			"Lorcann a une excellente pédagogie et de fortes compétences techniques. Il m'a aidé à développer mon vernis technique sur des sujets liés à la blockchain. Je le recommande vivement !",
	},
    {
        name: "Ramzi Melliti",
        role: "Co-fondateur et CTO de Cli3nts",
        comment: "J'ai eu l'occasion d'avoir Lorcann en tant qu'étudiant à la web@cademie, il est très à l'aise sur tout ce qui touche au développement, quel que soit le langage. Très bon et polyvalent."
    },
    {
        name: "Christian Mohindo",
        role: "Développeur",
        comment: "Ce que je peux dire de Lorcann, après l'avoir fréquenté et travaillé avec pendant toute une année scolaire, c'est qu'il a une curiosité insatiable et une motivation inébranlable. À chaque projet, il a toujours cette envie d'arriver au bout avec succès et de se surpasser."
    },

    
    
];
