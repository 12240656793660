import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { servicesData } from '../../constants/services';

export function Service() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <section className="services-area page-section scroll-to-page" id="services">
            <div className="custom-container">
                <div className="services-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-stream"></i> Services
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Ce que je <span>propose</span></h1>
                    </div>

                    <div className="services-items">
                        {servicesData.map((service, index) => (
                            <div className="service-item scroll-animation" data-aos='fade-up' key={index}>
                                <i className={service.icon}></i>
                                <h2>{service.title}</h2>
                                <p>{service.description}</p>
                                <span className="projects">{service.projectsCount}</span>
                            </div>
                        ))}
                    </div>    
                </div>
            </div>
        </section>
    );
}
