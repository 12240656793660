export const myClients = [
    { src: "../assets/images/client-2.png", alt: "Epitech Digital", url: "https://www.epitech.digital/" },
    { src: "../assets/images/client-12.png", alt: "ESD", url: "https://ecole-du-digital.com/" },
    { src: "../assets/images/client-7.png", alt: "Digitowl", url: "https://www.linkedin.com/company/digitowl-school/" },
    { src: "../assets/images/client-13.png", alt: "Openclassroom", url: "https://openclassrooms.com/" },
    { src: "../assets/images/client-11.png", alt: "Epita", url: "https://www.epita.fr/" },
    { src: "../assets/images/client-5.png", alt: "Sparks", url: "https://www.sparks-formation.com/" },
    { src: "../assets/images/client-4.png", alt: "Veolia", url: "https://www.service.eau.veolia.fr/home.html" },
    { src: "../assets/images/client-1.png", alt: "Acadee", url: "https://www.acadee-formation.com/" },
    { src: "../assets/images/client-3.png", alt: "ISG", url: "https://www.isg.fr/" },
    { src: "../assets/images/client-10.png", alt: "DigitalCampus", url: "https://www.digital-campus.fr/" },
    { src: "../assets/images/client-6.png", alt: "M2i", url: "https://www.m2iformation.fr/" },
    { src: "../assets/images/client-8.png", alt: "Just Mining", url: "https://just-mining.com" },
    // Add URLs for the remaining logos
];
