import React from 'react';
import { LeftMenu } from './Global-content/LeftMenu';
import { Hero } from './Main-Components/Hero';
// import { About } from './Main-Components/About';
// import { Resume } from './Main-Components/Resume';
import { Service } from './Main-Components/Service';
import { Skills } from './Main-Components/Skills';
// import { Portfolio } from './Main-Components/Portfolio';
import { Testimonials } from './Main-Components/Testimonials';
import { MyClients } from './Main-Components/MyClients';
import { Pricing } from './Main-Components/Pricing';
// import Contact from './Main-Components/Contact';

export default function Main() {
	return (
		<main className='drake-main'>
			<div id='smooth-wrapper'>
				<div id='smooth-content'>
					<LeftMenu /> {/* this component will used by mobile devices */}
					<Hero />
					<Service />
					<MyClients />
					<Skills />
					{/* <About /> */}
					{/* <Resume /> */}
					{/* <Portfolio /> */}
					<Testimonials />
					<Pricing />
					{/* <Contact /> */}
				</div>
			</div>
		</main>
	);
}
