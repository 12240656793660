export const servicesData = [
    {
        icon: "las la-code",
        title: "Développement Web",
        description: "Je jongle entre le Web classique, le Web3 et les dernières technos pour créer les solutions de demain. Que tu aies une idée à concrétiser ou que tu cherches simplement à améliorer ta présence en ligne, je suis là pour t'aider à réussir.",
        projectsCount: "+30 projets à mon actif"
    },
    {
        icon: "las la-user-tie",
        title: "Formation et coaching",
        description: "Mon approche repose sur une communication claire, et une compréhension approfondie des bases. J'accorde énormement d'importance aux besoins de chaque personne. J'offre également des sessions de coaching personnalisées pour aider chacun à atteindre son plein potentiel. ",
        projectsCount: "+300 personnes accompagnés "
    },
    {
        icon: "las la-chalkboard-teacher",
        title: "Interventions pédagogiques",
        description: "Je passe de temps en temps dans les écoles ou les organismes de formations pour parler de blockchain ou de technologie web. Ateliers, workshops... je suis intervenu dans une dizaine de structures.",
        projectsCount: "+100 interventions/cours donnés"
    }
];
