export const pricingPackages = [
	{
		name: 'Formation',
		// description: "ou workshop, et coaching.",
		price: '100 € / heure',
		features: [
			'Sessions interactives et pratiques',
			'Cours sur mesure en fonction de vos besoins',
			'Blockchain, Ethereum, Solidity, Web3',
			'Disponible en ligne ou en personne',
			'Adapté aux débutants et aux professionnels',
			'Support post-formation de 3 mois',
		],
		buttonText: 'choisir ce forfait',
		buttonLink:
			'https://api.whatsapp.com/send/?phone=0698670746&text="Bonjour Lorcann, je suis intéressé par une formation, peux-tu m\'en dire plus ?"&type=phone_number&app_absent=0',
	},
	{
		name: 'Développement',
		// description: "Sur mesure.",
		price: '650 € / jour',
		features: [
			'Développement de smart contracts',
			'Création de DApps (Applications Décentralisées)',
			'Intégration Web3',
			'Conseil en stratégie blockchain',
			'Optimisation et audits de sécurité',
			'Support technique continu',
		],
		buttonText: 'choisir ce forfait',
		buttonLink:
			'https://api.whatsapp.com/send/?phone=0698670746&text="Bonjour Lorcann, je suis intéressé par un développement pour mon projet, peux-tu m\'en dire plus ?"&type=phone_number&app_absent=0',
	},
	// Ajouter d'autres forfaits si nécessaire
];
