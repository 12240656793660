export const profileData = {
    logoImage: "./assets/images/logo.png",
    designation: "Développeur Web3",
    profileImage: "./assets/images/me.jpg",
    email: "contact@lorcannrauzduel.fr",
    address: "Basé en France",
    copyright: `© ${new Date().getFullYear()} Lorcann. All Rights Reserved.`,
    socialLinks: {
        linkedin: "https://www.linkedin.com/in/lorcannrauzduel/",
        instagram: "https://instagram.com/lorcann.pro/",
        github: "https://github.com/lorcannrauzduel/"
    }
};